import React from 'react';
import './App.scss';
import Main from './components/main/main';

const App = () => {
    return (
        <div className="App">
            <Main />
        </div>
    );
};

export default App;
