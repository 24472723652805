import React from 'react';
import './main.scss';

const Main = () => {
    return (
        <div className="header">
            This is <b>SentryFear</b> site
        </div>
    );
};

export default Main;
